<template>
  <div class="container">
    <div v-show="alertMessage" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="alertMessage = ''"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i>
      {{alertMessage}}
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label class="form-label">Date selection</label>
        <calendar-day-picker
          v-model="calendar"
          class="calendar-container"
          :range="true"
          @input="onCalendarInput"/>
        <small class="text-muted">Select dates to check the summary and reconciliations.</small>
      </div>
      <div v-if="can(uiPermissions.PAYMENT_RECONCILIATIONS_UPLOAD)" class="form-group col-md-6">
        <label class="form-label">Import checkout report</label>
        <div class="input-group">
          <div class="flex-1 position-relative">
            <input
              ref="fileInput"
              type="file"
              accept=".csv"
              class="form-control p-1"
              :disabled="loading"
              @change="handleFileChange"
              @input="clearSelectedFile($event)"/>
            <i
              v-if="selectedFile"
              class="fe fe-x clearable"
              @click="clearSelectedFile($event, true)">
            </i>
          </div>
          <div class="input-group-append">
            <button
              class="btn btn-primary"
              :disabled="!selectedFile || loading"
              @click="handleUpload">
              <i v-if="loading" class="fe fe-loader spin-icon"></i>
              Upload
            </button>
          </div>
        </div>
        <small class="text-muted">You can import checkout report without date selection.</small>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarDayPicker from '@/components/CalendarDayPicker';
import {paymentReconciliations} from '@/services';
import {captureException} from '@sentry/vue';
import moment from 'moment-timezone';

export default {
  name: 'PaymentReconciliationFilter',
  components: {
    CalendarDayPicker,
  },
  data() {
    return {
      calendar: {
        confirm: true,
        startDate: [],
      },
      selectedFile: null,
      loading: false,
      alertMessage: '',
    };
  },
  mounted() {
    // check if the query param has start_date and end_date and emit
    if (this.$route.query.start_date && this.$route.query.end_date) {
      this.calendar.startDate = [
        moment(this.$route.query.start_date),
        moment(this.$route.query.end_date),
      ];

      this.$emit('onDateSelected', this.calendar.startDate);
    }
  },
  methods: {
    onCalendarInput(weekObject) {
      this.$emit('onDateSelected', weekObject.startDate);
    },
    handleFileChange(e) {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];

        if (file.size > 10 * 1000000) { // 10 MB
          this.$toasted.error('File too large. Files cannot exceed 10 MB.');
          return;
        }

        this.selectedFile = file;
      }
    },
    clearSelectedFile(event, confirm = false) {
      if (event?.target?.value || (confirm && !window.confirm('Are you sure that you want to remove the file?'))) {
        return;
      }

      this.$refs.fileInput.value = null;
      this.selectedFile = null;
    },
    async handleUpload() {
      this.alertMessage = '';

      if (!this.selectedFile) {
        this.$toasted.error('Please select a file to upload.');
      }

      try {
        this.loading = true;
        const {data} = await paymentReconciliations.upload(this.selectedFile);
        this.alertMessage = data.message;
      }
      catch (error) {
        captureException(error);
        this.$toasted.error('An error occurred while uploading the file. Please try again.');
      }
      finally {
        this.loading = false;
        this.clearSelectedFile();
      }
    },
  },
};
</script>

<style scoped>
.clearable {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}
</style>
